import { Component, OnInit } from '@angular/core';
import InverseCaptchaService from '../inverse-captcha.service'
import { getHalfwayRoundCaptcha, getNextDigitCaptcha } from '../advent-of-code-2017/inverse-captcha';

@Component({
  selector: 'app-inverse-captcha',
  templateUrl: './inverse-captcha.component.html',
  styleUrls: ['./inverse-captcha.component.sass']
})
export class InverseCaptchaComponent implements OnInit {

  nextDigitCaptcha?: number
  halfwayRoundCaptcha?: number

  constructor(private inverseCaptchaService: InverseCaptchaService ) { }

  ngOnInit(): void {
    this.inverseCaptchaService.getInput().subscribe((s: string) => {
      this.nextDigitCaptcha = getNextDigitCaptcha(s)
      this.halfwayRoundCaptcha = getHalfwayRoundCaptcha(s)
    }).unsubscribe()
  }

}
