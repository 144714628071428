import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-corruption-checksum',
  templateUrl: './corruption-checksum.component.html',
  styleUrls: ['./corruption-checksum.component.sass']
})
export class CorruptionChecksumComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
