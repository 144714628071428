
const inputStringToNumberArray = (s: string): number[] => {
    return s.split('').map(v => parseInt(v, 10))
}

export const getNextDigitCaptcha = (input: string): number => {
    return inputStringToNumberArray(input).map((v, i, arr) => {
      const indexOffset = 1
      const nv = arr[i + indexOffset] === undefined ? arr[0] : arr[i + indexOffset]
      return v === nv ? v : 0
    }).reduce((pv, v) => pv + v)
}

export const getHalfwayRoundCaptcha = (input: string): number => {
  const nums = inputStringToNumberArray(input)
  const nums1 = nums.slice(0, nums.length / 2)
  const nums2 = nums.slice(nums.length / 2, nums.length)
  return nums1.map((v, i) => {
    return v === nums2[i] ? v + nums2[i] : 0
  }).reduce((pv, v) => pv + v)
}