<h1>Advent of Code Ng</h1>

<h2>TODO List</h2>

<ul>
  <li>Deactivate router links for the current view</li>
</ul>

<nav>
  <ul>
    <li><a routerLink="/">Home</a></li>
    <li><a routerLink="/inverse-captcha">Day 1: Inverse Captcha</a></li>
    <li><a routerLink="/corruption-checksum">Day 2: Corruption Checksum</a></li>
  </ul>
</nav>
<router-outlet></router-outlet><!-- The "view" will render here. -->
